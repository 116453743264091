import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import { setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';

import rf from '@/requests/RequestFactory';
import { getRefreshToken, removeRefreshToken, saveRefreshToken } from '@/utils/cookies';
import { getToken, removeToken, saveToken } from '@/utils/localStorage';
import { toast } from 'react-toastify';
// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    // isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = getToken();
        const refreshToken = getRefreshToken();

        if (accessToken && refreshToken) {
          setSession(accessToken);

          // const response = await rf.getRequest('AuthRequest').getInfo();
          // console.log({ response });
          // const { user } = response?.data?.data || {};

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: null,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        return err;
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response = await rf.getRequest('AuthRequest').login({ email, password });

      const { access_token, refresh_token, expires_in, user } = response?.data?.data || {};

      setSession(access_token, expires_in);
      saveRefreshToken(refresh_token, expires_in);

      dispatch({
        type: Types.Login,
        payload: {
          user,
        },
      });
      toast('Login successfully!');
    } catch (err) {
      toast.error('Login failed!');
      throw err;
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await rf
      .getRequest('AuthRequest')
      .register({ email, password, firstName, lastName });
    const { access_token, user } = response.data;

    saveToken(access_token);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    try {
      await rf.getRequest('AuthRequest').logout();
      setSession(null);
      removeToken();
      removeRefreshToken();
      dispatch({ type: Types.Logout });
      toast('Logout successfully!');
    } catch (err) {
      console.error(err);
      toast.error('Logout failed!');
      throw err;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
