import axios, { AxiosResponse, AxiosError } from 'axios';
// config
import { HOST_API, API_CLIENT_ID } from '../config';
import { getRefreshToken, removeRefreshToken, saveRefreshToken } from './cookies';
import { removeToken, saveToken } from './localStorage';
import rf from '@/requests/RequestFactory';
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Client-ID': API_CLIENT_ID,
  },
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const refreshToken = getRefreshToken();

    if (error.response?.status === 401) {
      const isRequestRefreshToken = error.config.url?.includes('users/refresh_token');

      if (isRequestRefreshToken) {
        removeRefreshToken();
      }
      if (!refreshToken) {
        return Promise.reject(error);
      }
      removeToken();
      try {
        const params = { refresh_token: refreshToken };
        const response = await rf.getRequest('AuthRequest').refreshToken(params);
        const { access_token, refresh_token } = response.data;
        if (access_token) {
          saveToken(access_token);
          saveRefreshToken(refresh_token);
        }
      } catch (error) {
        removeRefreshToken();
        return Promise.reject(error);
      }
    }
    return Promise.reject(error || 'Something went wrong');
  }
);

export default axiosInstance;
