// @mui
import { Stack, Typography, Box, Link } from '@mui/material';
import { useLocation, NavLink as RouterLink } from 'react-router-dom';
import Iconify from '@/components/Iconify';
import { ListItemStyle } from '@/components/nav-section/vertical/style';
import { PATH_DASHBOARD } from '@/routes/paths';
// ----------------------------------------------------------------------
interface Props {
  isCollapse: boolean;
}

export default function NavbarSettings({ isCollapse }: Props) {
  const { pathname } = useLocation();
  const active = pathname.includes(PATH_DASHBOARD.general.settings);

  return (
    <Stack
      spacing={3}
      sx={{ px: 2, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      <Link
        to={PATH_DASHBOARD.general.settings}
        component={RouterLink}
        sx={{
          display: 'block',
          color: 'text.secondary',
          transition: (theme) => theme.transitions.create('all'),
          '&:hover': {
            color: 'info.main',
            textDecoration: 'none',
          },
        }}
      >
        <ListItemStyle active={active} depth={1} sx={{ boxShadow: 'none' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Iconify icon={'ant-design:setting-outlined'} width={20} height={20} />
            {!isCollapse ? (
              <Typography variant="body2" sx={{ textDecoration: 'none' }}>
                Settings
              </Typography>
            ) : null}
          </Box>
        </ListItemStyle>
      </Link>
    </Stack>
  );
}
