// import { Link as RouterLink } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// sections
import { LoginForm } from '../../sections/auth/login';
//logo
import Logo from '../../components/Logo';
// import { PATH_AUTH } from '../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 505,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundImage: 'url(/background/illustration.png)',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');
  // const smUp = useResponsive('up', 'sm');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          {/* <Logo /> */}
          {/* <Image src={logoMain} sx={{ width: 200 }} /> */}
          <Logo withText={true} />
          {/* {smUp && (
            <Typography variant="body2" sx={{ display: 'flex', gap: 1 }}>
              Don’t have an account?
              <Link
                component={RouterLink}
                to={PATH_AUTH.register}
                sx={{
                  color: '#00CCFF',
                }}
              >
                Register
              </Link>
            </Typography>
          )} */}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Box
              sx={{
                height: 300,
              }}
            >
              <Typography variant="h3" sx={{ pl: 10, pr: 6, mt: 10, mb: 5 }}>
                Secure, Fast, & Simple
              </Typography>
            </Box>
          </SectionStyle>
        )}

        <Container
          maxWidth="sm"
          sx={{
            position: 'relative',
          }}
        >
          <ContentStyle>
            {/* <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to ADROIT
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>

              <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={`https://minimal-assets-api-dev.vercel.app/assets/icons/auth/ic_${method}.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip>
            </Stack> */}

            <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>
              Login
            </Typography>

            <LoginForm />

            {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            )} */}
          </ContentStyle>
          <Box
            sx={{
              position: 'absolute',
              bottom: 30,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: '#A0AEC0',
              fontWeight: 600,
            }}
          >
            <Box> Version 1.0.0 </Box>
            <Box>Copyright 2023</Box>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
