import jwtDecode from 'jwt-decode';
// routes
// import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';
import { removeToken, saveToken } from './localStorage';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// const handleTokenExpired = (exp: number) => {
//   let expiredTimer;

//   const currentTime = Date.now();

//   // Test token expires after 10s
//   // const timeLeft = currentTime + 10000 - currentTime; // ~10s
//   const timeLeft = exp * 1000 - currentTime;

//   clearTimeout(expiredTimer);

//   expiredTimer = setTimeout(() => {
//     alert('Token expired');

//     removeToken()

//     window.location.href = PATH_AUTH.login;
//   }, timeLeft);
// };

const setSession = (accessToken: string | null, exp?: number) => {
  if (accessToken) {
    saveToken(accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    // const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~3 days
    // handleTokenExpired(exp);
  } else {
    removeToken();
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
