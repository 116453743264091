// ----------------------------------- test
declare module '@mui/material/styles' {
  interface Theme {
    btnColor?: {
      color?: {
        success?: string;
        pending?: string;
        failed?: string;
        unpaid?: string;
        paid?: string;
        cancelled?: string;
      };
      background?: {
        success?: string;
        pending?: string;
        failed?: string;
        unpaid?: string;
        paid?: string;
        cancelled?: string;
      };
    };
  }
  interface ThemeOptions {
    btnColor?: {
      color?: {
        success?: string;
        pending?: string;
        failed?: string;
        unpaid?: string;
        paid?: string;
        cancelled?: string;
      };
      background?: {
        success?: string;
        pending?: string;
        failed?: string;
        unpaid?: string;
        paid?: string;
        cancelled?: string;
      };
    };
  }
}

const btnColor = {
  color: {
    success: `#22C55E`,
    pending: '#FF784B',
    failed: '#FF4747',
    unpaid: '#936DFF',
    paid: '#22C55E',
    cancelled: '#ffffff',
  },
  background: {
    success: `rgba(34, 197, 94, 0.1)`,
    pending: 'rgba(255, 120, 75, 0.1)',
    failed: 'rgba(255, 71, 71, 0.1)',
    unpaid: 'rgba(147, 109, 255, 0.1)',
    paid: 'rgba(34, 197, 94, 0.1)',
    cancelled: 'rgba(140, 140, 140, 0.3)',
  },
} as const;

export default btnColor;
