import AuthRequest from './factory/AuthRequest';
import UserRequest from './factory/UserRequest';
import TransactionRequest from './factory/TransactionRequest';
import InvoiceRequest from './factory/InvoiceRequest';

const requestMap: any = {
  AuthRequest,
  UserRequest,
  TransactionRequest,
  InvoiceRequest,
};

const instances: any = {};

export default class RequestFactory {
  static getRequest(className: string) {
    const RequestClass = requestMap[className];
    // console.log({ RequestClass, className });
    if (!RequestClass) {
      throw new Error(`Invalid request class name: ${className}`);
    }

    let requestInstance = instances[className];
    if (!requestInstance) {
      requestInstance = new RequestClass();
      instances[className] = requestInstance;
    }
    return requestInstance;
  }
}
