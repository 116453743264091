import BaseRequest from '../BaseRequest';
import API_PATH from '../config';
/**
 * key base on host:port
 */
export default class UserRequest extends BaseRequest {
  getSettings() {
    const url = API_PATH.user.settings;
    return this.get(url);
  }

  getApiKey() {
    const url = API_PATH.user.apis;
    return this.get(url);
  }

  getBalances() {
    const url = API_PATH.user.balances;
    return this.get(url);
  }
}
