import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
// import Login from '../pages/auth/Login'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <DashboardHome /> },
        { path: 'wallet-transaction', element: <WalletTransaction /> },
        { path: 'payment-record', element: <PaymentRecord /> },
        { path: 'withdraw-record', element: <WithdrawRecord /> },
        { path: 'invoices', element: <Invoices /> },
        { path: 'invoice/:id', element: <InvoiceDetails /> },
        { path: 'settings', element: <Settings /> },
        { path: 'settings/business-information', element: <SettingsBusinessInformation /> },
        { path: 'settings/notifications', element: <SettingsNotification /> },
        { path: 'settings/account', element: <SettingsAccount /> },
        { path: 'settings/account/add-wallet', element: <SettingsAddWallet /> },
        { path: 'settings/api-access', element: <SettingsApiAccess /> },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ element: <HomePage />, index: true }],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const DashboardHome = Loadable(lazy(() => import('../pages/dashboard/dashboard/index')));
const WalletTransaction = Loadable(lazy(() => import('../pages/dashboard/walletPayment/index')));
const PaymentRecord = Loadable(lazy(() => import('../pages/dashboard/paymentRecord/index')));
const WithdrawRecord = Loadable(lazy(() => import('../pages/dashboard/withdrawRecord/index')));
const Invoices = Loadable(lazy(() => import('../pages/dashboard/invoices')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/invoices/view')));
const Settings = Loadable(lazy(() => import('../pages/dashboard/settings/index')));
const SettingsAccount = Loadable(lazy(() => import('../pages/dashboard/settings/AccountSettings')));
const SettingsAddWallet = Loadable(lazy(() => import('../pages/dashboard/settings/AddWallet')));
const SettingsApiAccess = Loadable(lazy(() => import('../pages/dashboard/settings/APIAccess')));
const SettingsBusinessInformation = Loadable(
  lazy(() => import('../pages/dashboard/settings/BusinessInformation'))
);
const SettingsNotification = Loadable(
  lazy(() => import('../pages/dashboard/settings/Notification'))
);

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
