import BaseRequest from '../BaseRequest';
import API_PATH from '../config';

/**
 * key base on host:port
 */
export default class AuthRequest extends BaseRequest {
  login(data: any) {
    const url = API_PATH.auth.login;
    return this.post(url, data);
  }

  refreshToken(data: any) {
    const url = API_PATH.auth.refresh;
    return this.post(url, data);
  }

  logout() {
    const url = API_PATH.auth.logout;
    return this.post(url);
  }

  forgotPassword(data: any) {
    const url = `users/forgot_password`;
    return this.post(url, data);
  }

  getInfo() {
    const url = `users`;
    return this.get(url);
  }

  updateInfo(data: any) {
    const url = `account/info`;
    return this.put(url, data);
  }
}
