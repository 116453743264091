import BaseRequest from '../BaseRequest';
import API_PATH from '../config';
/**
 * key base on host:port
 */
export default class InvoiceRequest extends BaseRequest {
  // params: { page, per_page }
  getInvoices(params: any) {
    const url = API_PATH.invoices.invoices;
    return this.get(url, params);
  }

  getInvoice(id: string) {
    const url = `${API_PATH.invoices.invoices}/${id}`;
    return this.get(url);
  }
}
