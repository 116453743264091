// @mui
import { List, Box, Typography } from '@mui/material';
//
import { NavSectionProps } from '../type';
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  return (
    <Box {...other} sx={{ mt: 4 }}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          {group?.displaySubheader ? (
            <Typography variant="subtitle1" sx={{ fontWeight: 'fontWeightBold' }} noWrap>
              {group.subheader}
            </Typography>
          ) : null}
          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
