import BaseRequest from '../BaseRequest';
import API_PATH from '../config';
/**
 * key base on host:port
 */
export default class TransactionRequest extends BaseRequest {
  // params: { transaction_type, filter_by, page, per_page }
  getTransactions(params: any) {
    const url = API_PATH.transaction.transaction;
    return this.get(url, params);
  }
}
