import Cookies from 'js-cookie';

const REFRESH_NAME = '_ut.rt';

export const saveRefreshToken = (token: string, exp?: number): void => {
  const date = new Date();
  exp = exp || 7;
  Cookies.set(REFRESH_NAME, token, {
    expires: new Date(date.setDate(date.getDate() + exp)),
  });
};
export const getRefreshToken = (): string | undefined => Cookies.get(REFRESH_NAME);
export const removeRefreshToken = (): void => Cookies.remove(REFRESH_NAME);
