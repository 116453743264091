import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Fab(theme: Theme) {
  return {
    MuiFab: {
      defaultProps: {
        color: 'info'
      },

      styleOverrides: {
        root: {
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.grey[400]
          }
        },
        primary: {
          boxShadow: theme.customShadows.info,
          '&:hover': {
            backgroundColor: theme.palette.info.dark
          }
        },
        secondary: {
          boxShadow: theme.customShadows.info,
          '&:hover': {
            backgroundColor: theme.palette.info.dark
          }
        },
        extended: {
          '& svg': {
            marginRight: theme.spacing(1)
          }
        }
      }
    }
  };
}
