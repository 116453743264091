import { HOST_API } from '@/config';
export const API_VERSION = '/v2/';
export const API_OAUTH = 'oauth/';

const USER = 'users/';

const API_PATH = {
  auth: {
    login: HOST_API + API_VERSION + USER,
    logout: HOST_API + API_VERSION + USER + 'signout/',
    refresh: HOST_API + API_VERSION + USER + 'refresh_token/',
  },
  transaction: {
    transaction: HOST_API + API_VERSION + 'transactions/',
  },
  invoices: {
    invoices: HOST_API + API_VERSION + 'invoices/',
  },
  user: {
    balances: HOST_API + API_VERSION + USER + 'balances/',
    settings: HOST_API + API_VERSION + USER + 'settings/',
    apis: HOST_API + API_VERSION + USER + 'apis/',
  },
};

export default API_PATH;
