import { isBrowser } from '@/utils';

const TOKEN_NAME = '_ut.token';

export const saveToken = (token: string) => {
  return isBrowser && token ? localStorage.setItem(TOKEN_NAME, token) : null;
};

export const getToken = () => {
  return isBrowser ? localStorage.getItem(TOKEN_NAME) : null;
};

export const removeToken = () => {
  return isBrowser ? localStorage.removeItem(TOKEN_NAME) : null;
};
